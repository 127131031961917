import { clsx } from "clsx"

import type { Nullish, PropsWithClassName } from "~/@types/generics"

import * as css from "./styles.css"

export type TPrice = {
  price: string
  compareAtPrice?: Nullish<string>
  percentage?: Nullish<number>
}
export type PriceProps = PropsWithClassName<TPrice>

function Price({ className, price, compareAtPrice, percentage }: PriceProps) {
  return (
    <div className={clsx(css.Price, className)}>
      {compareAtPrice && <span className={clsx(css.compareAtPrice)}>{compareAtPrice}</span>}
      {!!percentage && <span className={clsx(css.percentage)}>-{percentage}%</span>}
      {price}
    </div>
  )
}

export default Price
